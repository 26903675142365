<template>
    <div>
        <div class="page-wrapper">
        <div class="topbar-one">
            <div class="container">
                <div class="topbar-one__left">
                    <a href="#">zhyldyzacademy@protonmail.com</a>
                    <a href="#">+996 555 94 95 01</a>
                </div><!-- /.topbar-one__left -->
                <div class="topbar-one__right">
                    <a href="#">Login</a>
                    <a href="#">Register</a>
                </div><!-- /.topbar-one__right -->
            </div><!-- /.container -->
        </div><!-- /.topbar-one -->
        <header class="site-header site-header__header-one site-header__inner-page ">
            <nav class="navbar navbar-expand-lg navbar-light header-navigation stricky">
                <div class="container clearfix">
                    <!-- Brand and toggle get grouped for better mobile display -->
                    <div class="logo-box clearfix">
                        <a class="navbar-brand" href="index.html">
                            <img :src="require('../../assets/logo_1.png')" class="main-logo" width="128" alt="Awesome Image" />
                        </a>
                        <div class="header__social">
                            <a href="#" target="_blank"><i class="fab fa-twitter"></i></a>
                            <a href="https://www.facebook.com/zhyldyz.academy" target="_blank"><i class="fab fa-facebook-square"></i></a>
                            <a href="https://api.whatsapp.com/send/?phone=%2B996704949501&text&app_absent=0" target="_blank"><i class="fab fa-whatsapp"></i></a>
                            <a href="https://www.instagram.com/zhyldyz_academy/" target="_blank"><i class="fab fa-instagram"></i></a>
                        </div><!-- /.header__social -->
                        <button class="menu-toggler" data-target=".main-navigation">
                            <span class="kipso-icon-menu"></span>
                        </button>
                    </div><!-- /.logo-box -->
                    <!-- Collect the nav links, forms, and other content for toggling -->
                    <div class="main-navigation">
                        <ul class=" navigation-box">
                            <li>
                                <a @click="goTo('Home')" style="font-weight: 700;">Home</a>
                               <!--<ul class="sub-menu">
                                    <li><a href="index.html">Home 01</a></li>
                                    <li><a href="index-2.html">Home 02</a></li>
                                    <li><a href="index-3.html">Home 03</a></li>
                                    <li><a href="#">Header Versions</a>
                                        <ul class="sub-menu">
                                            <li><a href="index.html">Header 01</a></li>
                                            <li><a href="index-2.html">Header 02</a></li>
                                            <li><a href="index-3.html">Header 03</a></li>
                                        </ul>
                                    </li>
                                </ul> /.sub-menu -->
                            </li>
                            <!--<li>
                                <a href="#">Pages</a>
                                <ul class="sub-menu">
                                    <li><a href="about.html">About Page</a></li>
                                    <li><a href="gallery.html">Gallery</a></li>
                                    <li><a href="pricing.html">Pricing Plans</a></li>
                                    <li><a href="faq.html">FAQ'S</a></li>
                                </ul> 
                            </li>/.sub-menu -->
                            <li>
                                <a @click="goTo('Courses')" style="font-weight: 700;">Courses</a>
                                <!--<ul class="sub-menu">
                                    <li><a href="courses.html">Courses</a></li>
                                    <li><a href="course-details.html">Course Details</a></li>
                                </ul> /.sub-menu -->
                            </li>
                            <li class="current">
                                <a @click="goTo('Teachers')" style="font-weight: 700;">Teachers</a>
                                <!--<ul class="sub-menu">
                                    <li><a href="teachers.html">Teachers</a></li>
                                    <li><a href="team-details.html">Teachers Details</a></li>
                                    <li><a href="become-teacher.html">Become Teacher</a></li>
                                </ul> /.sub-menu -->
                            </li>
                            <li>
                                <a @click="goTo('News')" style="font-weight: 700;">News</a>
                                <!-- <ul class="sub-menu">
                                    <li><a href="news.html">News Page</a></li>
                                    <li><a href="news-details.html">News Details</a></li>
                                </ul>/.sub-menu -->
                            </li>
                            <li>
                                <a @click="goTo('About')" style="font-weight: 700;">About</a>
                            </li>
                        </ul>
                    </div><!-- /.navbar-collapse -->
                    <div class="right-side-box">
                        <a class="header__search-btn search-popup__toggler" href="#"><i class="kipso-icon-magnifying-glass"></i>
                            <!-- /.kipso-icon-magnifying-glass --></a>
                    </div><!-- /.right-side-box -->
                </div>
                <!-- /.container -->
            </nav>
            <div class="site-header__decor">
                <div class="site-header__decor-row">
                    <div class="site-header__decor-single">
                        <div class="site-header__decor-inner-1"></div><!-- /.site-header__decor-inner -->
                    </div><!-- /.site-header__decor-single -->
                    <div class="site-header__decor-single">
                        <div class="site-header__decor-inner-2"></div><!-- /.site-header__decor-inner -->
                    </div><!-- /.site-header__decor-single -->
                    <div class="site-header__decor-single">
                        <div class="site-header__decor-inner-3"></div><!-- /.site-header__decor-inner -->
                    </div><!-- /.site-header__decor-single -->
                </div><!-- /.site-header__decor-row -->
            </div><!-- /.site-header__decor -->
        </header><!-- /.site-header -->
        <section class="inner-banner">
            <div class="container">
                <ul class="list-unstyled thm-breadcrumb">
                    <li><a href="#">Home</a></li>
                    <li class="active"><a href="#">Teachers</a></li>
                </ul><!-- /.list-unstyled -->
                <h2 class="inner-banner__title">Teachers</h2><!-- /.inner-banner__title -->
            </div><!-- /.container -->
        </section><!-- /.inner-banner -->
        <section class="team-one team-page">
            <div class="container">
                <div class="row">
                    <div class="col-xl-3 col-lg-6 col-md-6 col-sm-12">
                        <div class="team-one__single">
                            <div class="team-one__image">
                                <img :src="require('../../assets/images/team-1-1.jpeg')" alt="">
                            </div><!-- /.team-one__image -->
                            <div class="team-one__content">
                                <h2 class="team-one__name"><a href="team-details.html">Adelaide Hunter</a></h2>
                                <!-- /.team-one__name -->
                                <p class="team-one__designation">Teacher</p><!-- /.team-one__designation -->
                                <p class="team-one__text">There are many varia of passages of lorem.</p>
                                <!-- /.team-one__text -->
                            </div><!-- /.team-one__content -->
                            <div class="team-one__social">
                                <a href="#"><i class="fab fa-twitter"></i></a>
                                <a href="#"><i class="fab fa-facebook-square"></i></a>
                                <a href="#"><i class="fab fa-pinterest-p"></i></a>
                                <a href="#"><i class="fab fa-instagram"></i></a>
                            </div><!-- /.team-one__social -->
                        </div><!-- /.team-one__single -->
                    </div><!-- /.col-lg-3 -->
                    <div class="col-xl-3 col-lg-6 col-md-6 col-sm-12">
                        <div class="team-one__single">
                            <div class="team-one__image">
                                <img :src="require('../../assets/images/team-1-2.jpeg')" alt="">
                            </div><!-- /.team-one__image -->
                            <div class="team-one__content">
                                <h2 class="team-one__name"><a href="team-details.html">Christina Newman</a></h2>
                                <!-- /.team-one__name -->
                                <p class="team-one__designation">Teacher</p><!-- /.team-one__designation -->
                                <p class="team-one__text">There are many varia of passages of lorem.</p>
                                <!-- /.team-one__text -->
                            </div><!-- /.team-one__content -->
                            <div class="team-one__social">
                                <a href="#"><i class="fab fa-twitter"></i></a>
                                <a href="#"><i class="fab fa-facebook-square"></i></a>
                                <a href="#"><i class="fab fa-pinterest-p"></i></a>
                                <a href="#"><i class="fab fa-instagram"></i></a>
                            </div><!-- /.team-one__social -->
                        </div><!-- /.team-one__single -->
                    </div><!-- /.col-lg-3 -->
                    <div class="col-xl-3 col-lg-6 col-md-6 col-sm-12">
                        <div class="team-one__single">
                            <div class="team-one__image">
                                <img :src="require('../../assets/images/team-1-3.jpeg')" alt="">
                            </div><!-- /.team-one__image -->
                            <div class="team-one__content">
                                <h2 class="team-one__name"><a href="team-details.html">Gilbert Daniels</a></h2>
                                <!-- /.team-one__name -->
                                <p class="team-one__designation">Teacher</p><!-- /.team-one__designation -->
                                <p class="team-one__text">There are many varia of passages of lorem.</p>
                                <!-- /.team-one__text -->
                            </div><!-- /.team-one__content -->
                            <div class="team-one__social">
                                <a href="#"><i class="fab fa-twitter"></i></a>
                                <a href="#"><i class="fab fa-facebook-square"></i></a>
                                <a href="#"><i class="fab fa-pinterest-p"></i></a>
                                <a href="#"><i class="fab fa-instagram"></i></a>
                            </div><!-- /.team-one__social -->
                        </div><!-- /.team-one__single -->
                    </div><!-- /.col-lg-3 -->
                    <div class="col-xl-3 col-lg-6 col-md-6 col-sm-12">
                        <div class="team-one__single">
                            <div class="team-one__image">
                                <img :src="require('../../assets/images/team-1-4.jpeg')" alt="">
                            </div><!-- /.team-one__image -->
                            <div class="team-one__content">
                                <h2 class="team-one__name"><a href="team-details.html">Austin Caldwell</a></h2>
                                <!-- /.team-one__name -->
                                <p class="team-one__designation">Teacher</p><!-- /.team-one__designation -->
                                <p class="team-one__text">There are many varia of passages of lorem.</p>
                                <!-- /.team-one__text -->
                            </div><!-- /.team-one__content -->
                            <div class="team-one__social">
                                <a href="#"><i class="fab fa-twitter"></i></a>
                                <a href="#"><i class="fab fa-facebook-square"></i></a>
                                <a href="#"><i class="fab fa-pinterest-p"></i></a>
                                <a href="#"><i class="fab fa-instagram"></i></a>
                            </div><!-- /.team-one__social -->
                        </div><!-- /.team-one__single -->
                    </div><!-- /.col-lg-3 -->
                    <div class="col-xl-3 col-lg-6 col-md-6 col-sm-12">
                        <div class="team-one__single">
                            <div class="team-one__image">
                                <img :src="require('../../assets/images/team-1-5.jpeg')" alt="">
                            </div><!-- /.team-one__image -->
                            <div class="team-one__content">
                                <h2 class="team-one__name"><a href="team-details.html">Phoebe Park</a></h2>
                                <!-- /.team-one__name -->
                                <p class="team-one__designation">Teacher</p><!-- /.team-one__designation -->
                                <p class="team-one__text">There are many varia of passages of lorem.</p>
                                <!-- /.team-one__text -->
                            </div><!-- /.team-one__content -->
                            <div class="team-one__social">
                                <a href="#"><i class="fab fa-twitter"></i></a>
                                <a href="#"><i class="fab fa-facebook-square"></i></a>
                                <a href="#"><i class="fab fa-pinterest-p"></i></a>
                                <a href="#"><i class="fab fa-instagram"></i></a>
                            </div><!-- /.team-one__social -->
                        </div><!-- /.team-one__single -->
                    </div><!-- /.col-lg-3 -->
                    <div class="col-xl-3 col-lg-6 col-md-6 col-sm-12">
                        <div class="team-one__single">
                            <div class="team-one__image">
                                <img :src="require('../../assets/images/team-1-6.jpeg')" alt="">
                            </div><!-- /.team-one__image -->
                            <div class="team-one__content">
                                <h2 class="team-one__name"><a href="team-details.html">Matilda Hawkins</a></h2>
                                <!-- /.team-one__name -->
                                <p class="team-one__designation">Teacher</p><!-- /.team-one__designation -->
                                <p class="team-one__text">There are many varia of passages of lorem.</p>
                                <!-- /.team-one__text -->
                            </div><!-- /.team-one__content -->
                            <div class="team-one__social">
                                <a href="#"><i class="fab fa-twitter"></i></a>
                                <a href="#"><i class="fab fa-facebook-square"></i></a>
                                <a href="#"><i class="fab fa-pinterest-p"></i></a>
                                <a href="#"><i class="fab fa-instagram"></i></a>
                            </div><!-- /.team-one__social -->
                        </div><!-- /.team-one__single -->
                    </div><!-- /.col-lg-3 -->
                    <div class="col-xl-3 col-lg-6 col-md-6 col-sm-12">
                        <div class="team-one__single">
                            <div class="team-one__image">
                                <img :src="require('../../assets/images/team-1-7.jpeg')" alt="">
                            </div><!-- /.team-one__image -->
                            <div class="team-one__content">
                                <h2 class="team-one__name"><a href="team-details.html">Lizzie Butler</a></h2>
                                <!-- /.team-one__name -->
                                <p class="team-one__designation">Teacher</p><!-- /.team-one__designation -->
                                <p class="team-one__text">There are many varia of passages of lorem.</p>
                                <!-- /.team-one__text -->
                            </div><!-- /.team-one__content -->
                            <div class="team-one__social">
                                <a href="#"><i class="fab fa-twitter"></i></a>
                                <a href="#"><i class="fab fa-facebook-square"></i></a>
                                <a href="#"><i class="fab fa-pinterest-p"></i></a>
                                <a href="#"><i class="fab fa-instagram"></i></a>
                            </div><!-- /.team-one__social -->
                        </div><!-- /.team-one__single -->
                    </div><!-- /.col-lg-3 -->
                    <div class="col-xl-3 col-lg-6 col-md-6 col-sm-12">
                        <div class="team-one__single">
                            <div class="team-one__image">
                                <img :src="require('../../assets/images/team-1-8.jpeg')" alt="">
                            </div><!-- /.team-one__image -->
                            <div class="team-one__content">
                                <h2 class="team-one__name"><a href="team-details.html">Trevor Turner</a></h2>
                                <!-- /.team-one__name -->
                                <p class="team-one__designation">Teacher</p><!-- /.team-one__designation -->
                                <p class="team-one__text">There are many varia of passages of lorem.</p>
                                <!-- /.team-one__text -->
                            </div><!-- /.team-one__content -->
                            <div class="team-one__social">
                                <a href="#"><i class="fab fa-twitter"></i></a>
                                <a href="#"><i class="fab fa-facebook-square"></i></a>
                                <a href="#"><i class="fab fa-pinterest-p"></i></a>
                                <a href="#"><i class="fab fa-instagram"></i></a>
                            </div><!-- /.team-one__social -->
                        </div><!-- /.team-one__single -->
                    </div><!-- /.col-lg-3 -->
                </div><!-- /.row -->
            </div><!-- /.container -->
        </section><!-- /.team-one team-page -->
        <div class="cta-two">
            <div class="container-fluid">
                <div class="row no-gutters">
                    <div class="col-lg-6 thm-base-bg">
                        <div class="cta-two__single">
                            <div class="cta-two__icon">
                                <span><i class="kipso-icon-professor"></i><!-- /.kipso-icon-professor --></span>
                            </div><!-- /.cta-two__icon -->
                            <div class="cta-two__content">
                                <h2 class="cta-two__title">Become an teacher</h2><!-- /.cta-two__title -->
                                <p class="cta-two__text">There are many variations of passages of lore available but <br> the
                                    majority have suffered alteration in some form.</p><!-- /.cta-two__text -->
                                <a href="#" class="thm-btn cta-two__btn">Start Teaching</a><!-- /.thm-btn cta-two__btn -->
                            </div><!-- /.cta-two__content -->
                        </div><!-- /.cta-two__single -->
                    </div><!-- /.col-lg-6 -->
                    <div class="col-lg-6 thm-base-bg-2">
                        <div class="cta-two__single">
                            <div class="cta-two__icon">
                                <span><i class="kipso-icon-knowledge"></i><!-- /.kipso-icon-knowledge --></span>
                            </div><!-- /.cta-two__icon -->
                            <div class="cta-two__content">
                                <h2 class="cta-two__title">Join our community</h2><!-- /.cta-two__title -->
                                <p class="cta-two__text">There are many variations of passages of lore available but <br> the
                                    majority have suffered alteration in some form.</p><!-- /.cta-two__text -->
                                <a href="#" class="thm-btn cta-two__btn">Start Learning</a><!-- /.thm-btn cta-two__btn -->
                            </div><!-- /.cta-two__content -->
                        </div><!-- /.cta-two__single -->
                    </div><!-- /.col-lg-6 -->
                </div><!-- /.row no-gutters -->
            </div><!-- /.container-fluid -->
        </div><!-- /.cta-two -->
        <Footer></Footer>

    </div><!-- /.page-wrapper -->
    </div>
</template>
<script>
import Footer from '../../components/footer/Footer.vue'
import '../../assets/css/style.css'
export default {
    name: "Teachers",
    components: {
        Footer
    },
    data: () => ({

    }),
     methods :{
      goTo (url) {
         this.link = url;
         switch (url) {
            case 'Courses':
              this.$router.push({ name: 'Courses' });
               break;
            case 'Teachers':
               this.$router.push({ name: 'Teachers' });
               break;
            case 'News':
               this.$router.push({ name: 'News' });
               break;
            case 'About':
               this.$router.push({ name: 'About' });
               break;
            case 'Home':
               this.$router.push({ name: 'Main' });
               break;
         }
      },
    }
}
</script>